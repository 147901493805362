import { useStaticQuery, graphql } from 'gatsby';

export default function searchQuery() {
  const nodeServices = useStaticQuery(graphql`
  query MyQuery {
    allNodeBlog {
      edges {
        node {
          id
          type: __typename
          title
          langcode
          wysiwyg: body {
            summary
          }
          link: path {
            uri: alias
            langcode
          }
          relationships {
            image: field_thumbnail {
              name
              data: gatsbyImageData(layout: FIXED)
              attributes: field_media_image {
                alt
                title
              }
            }
            tags: field_tags {
              id: drupal_internal__tid
              name
            }
          }
          created
        }
      }
    }
    allNodeWhitepaper {
      edges {
        node {
          id
          type: __typename
          title
          langcode
          wysiwyg: body {
            summary
          }
          link: path {
            uri: alias
            langcode
          }
          relationships {
            image: field_thumbnail {
              name
              data: gatsbyImageData(layout: FIXED)
              attributes: field_media_image {
                alt
                title
              }
            }
          }
          created
        }
      }
    }
    allNodeSuccessStory {
      edges {
        node {
          id
          type: __typename
          title
          langcode
          wysiwyg: body {
            summary
          }
          link: path {
            uri: alias
            langcode
          }
          relationships {
            image: field_image {
              filename
              data: gatsbyImageData(layout: FIXED)
              attributes: field_media_image {
                alt
                title
              }
            }
          }
          created
        }
      }
    }
    allNodeIndustry {
      edges {
        node {
          id
          type: __typename
          title
          langcode
          wysiwyg: body {
            summary
          }
          link: path {
            uri: alias
            langcode
          }
          relationships {
            image: field_thumbnail {
              name
              data: gatsbyImageData(layout: FIXED)
              attributes: field_media_image {
                alt
                title
              }
            }
          }
          created
        }
      }
    }
    allNodeSolution {
      edges {
        node {
          id
          type: __typename
          title
          langcode
          wysiwyg: body {
            summary
          }
          link: path {
            uri: alias
            langcode
          }
          relationships {
            image: field_thumbnail {
              name
              data: gatsbyImageData(layout: FIXED)
              attributes: field_media_image {
                alt
                title
              }
            }
          }
          created
        }
      }
    }
    allNodeCareer {
      edges {
        node {
          id
          type: __typename
          title
          langcode
          wysiwyg: body {
            summary
          }
          link: path {
            uri: alias
            langcode
          }
        }
      }
    }
    allTaxonomyTermTags {
      edges {
        node {
          id: drupal_internal__tid
          name
        }
      }
    }
  }
  `);

  return nodeServices;
}
