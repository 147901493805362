import React, { ReactElement } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneSquareAlt } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export default function CallUs(): ReactElement {
  const { t } = useTranslation();

  return (
    <a
      className="nav-link text-uppercase small p-0 d-md-flex d-lg-flex align-items-center d-none d-sm-block"
      href="tel:+5072099002"
    >
      <FontAwesomeIcon size="lg" icon={faPhoneSquareAlt as IconProp} />
      <span className="ml-2">{t('call us')}</span>
    </a>
  );
}
