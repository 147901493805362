const defaultResponsiveSettings = {
  slidesPerRow: 1,
  slidesToShow: 1,
};

exports.defaultResponsiveSettings = defaultResponsiveSettings;

exports.globalSliderSettings = {
  arrows: true,
  autoplay: true,
  infinite: true,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: true,
  pauseOnHover: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: defaultResponsiveSettings
    }
  ]
};
