import React from 'react';
import { Navbar } from 'react-bootstrap';
import { Link as GatsbyLink } from 'gatsby';

import { getPathFromInternalUrl } from '@core/helpers/link';

import { Graphql } from '@models/graphql';
import { MenuLink } from '@models/menu-link';

import allTopBarMenu from './default-query';
import TranslationSwitcher from './translation-switcher';

import './top-bar.scss';

type TopBarProps = {
  node: Graphql
}

export default function TopBar({ node }: TopBarProps): React.ReactElement {
  const links = allTopBarMenu();

  const isContact = ({ link }: MenuLink) => {
    const path = link?.alias || link?.uri || '';

    return path.includes('contact');
  };

  return (
    <Navbar collapseOnSelect className="top-bar p-0">
      <div className="container">
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <div className="navbar-nav ml-auto">
            {links.map((link: MenuLink) => (
              <GatsbyLink
                key={link.drupalId}
                className={`nav-link small text-marine ${
                  isContact(link) ? 'persists-active' : ''
                }`}
                to={
                  link?.link?.alias ||
                  getPathFromInternalUrl(link?.link?.uri || '')
                }
              >
                {link.title}
              </GatsbyLink>
            ))}
            <div className="d-none d-lg-block">
              <TranslationSwitcher node={node} />
            </div>
          </div>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
}
