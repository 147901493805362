import React from 'react';

import NavigationBarContact from '@core/layout/navigation-bar/contact/contact-bar';
import TopBar from '@core/layout/top-bar/top-bar';
import MegaMenu from '@core/layout/navigation-bar/mega-menu/mega-menu';
import { useMobileL } from '@core/helpers/responsive';

import { LocationModel } from '@models/location';
import { Graphql } from '@models/graphql';

const { onlyLogo, onlyContact } = require('@config/styles');


type HeaderProps = {
  node: Graphql;
  location: LocationModel;
};

export default function Header({ node, location }: HeaderProps): React.ReactElement {
  const { headerStyle } = node || {};
  const isOnlyContact = headerStyle === onlyContact;
  const isNavigationLinkDisabled = headerStyle === onlyLogo;
  const isMobileL = useMobileL();

  return isOnlyContact ? <NavigationBarContact /> : (
    <div className="sticky shadow-sm">
      {!isMobileL && <TopBar node={node} />}
      <MegaMenu node={node} location={location} navigationDisabled={isNavigationLinkDisabled} />
    </div>
  );
}
