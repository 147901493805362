import { isBrowser } from './ssr';

export function cleanUrl(url: string) {
  return url;
}

export function getPathFromInternalUrl(url: string, isExternal: boolean = false) {
  if (!url) {
    return '#';
  }

  const path = url
    .replace('entity:', '')
    .replace('internal:', '');

  if (isExternal) {
    return removeTrailingSlash(`${process.env.GATSBY_BASE_URL_API}${path}`);
  }

  return removeTrailingSlash(path);
}

export function getHomePath(): string|null {
  if (isBrowser) {
    const segments = window.location.pathname.split('/');

    if (segments.length > 2) {
      return `/${segments[1]}/`;
    }
  }

  return null;
}

export function removeTrailingSlash(url: string) {
  if (!url) {
    return ''
  }

  return url.replace(/\/$/, '');
}
