/* eslint-disable */
// @ts-nocheck

import React from 'react';
import { Script } from 'gatsby';

export const onRenderBody = ({ pathname, setHtmlAttributes }) => {
  const arrayPathname = pathname.split('/')
  let lang = 'en'

  if (pathname === '/' || pathname === '/404' || pathname === '/404.html' || pathname.toLowerCase().startsWith('/lp/')) {
    lang = 'en'
  } else if (arrayPathname.length >= 2) {
    lang = arrayPathname[1]
  }

  setHtmlAttributes({ lang });
};

export const wrapPageElement = ({ element }) => (
  <>
    {element}
    <Script
      id="message-bird"
      dangerouslySetInnerHTML={{
        __html: `var MessageBirdChatWidgetSettings = {
                  widgetId: '27dc0be0-b6d5-460a-bff6-e7143c14bae2',
                  initializeOnLoad: true,
                };

                !function(){"use strict";if(Boolean(document.getElementById("live-chat-widget-script")))console.error("MessageBirdChatWidget: Snippet loaded twice on page");else{var e,t;window.MessageBirdChatWidget={},window.MessageBirdChatWidget.queue=[];for(var i=["init","setConfig","toggleChat","identify","hide","on","shutdown"],n=function(){var e=i[d];window.MessageBirdChatWidget[e]=function(){for(var t=arguments.length,i=new Array(t),n=0;n<t;n++)i[n]=arguments[n];window.MessageBirdChatWidget.queue.push([[e,i]])}},d=0;d<i.length;d++)n();var a=(null===(e=window)||void 0===e||null===(t=e.MessageBirdChatWidgetSettings)||void 0===t?void 0:t.widgetId)||"",o=function(){var e,t=document.createElement("script");t.type="text/javascript",t.src="https://livechat.messagebird.com/bootstrap.js?widgetId=".concat(a),t.async=!0,t.id="live-chat-widget-script";var i=document.getElementsByTagName("script")[0];null==i||null===(e=i.parentNode)||void 0===e||e.insertBefore(t,i)};"complete"===document.readyState?o():window.attachEvent?window.attachEvent("onload",o):window.addEventListener("load",o,!1)}}();
                `,
      }}
    />

    <Script
      id="gtag"
      src="https://www.googletagmanager.com/gtag/js?id=UA-18995008-1"
      onLoad={() => {
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          dataLayer.push(arguments);
        }

        gtag('js', new Date());
        gtag('config', 'UA-18995008-1');
      }}
    />

    <Script
      id="ga4"
      src="https://www.googletagmanager.com/gtag/js?id=G-85ZMN92ZPP"
      onLoad={() => {
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', 'G-85ZMN92ZPP');
      }}
    />

    <Script
      id="gtag-amp"
      src="https://www.googletagmanager.com/gtag/js?id=AW-966025032"
      onLoad={() => {
        window.dataLayer = window.dataLayer || [];

        function gtag() {
          dataLayer.push(arguments);
        }

        gtag('js', new Date());
        gtag('config', 'AW-966025032');
      }}
    />

    <Script
      id="hotjars"
      dangerouslySetInnerHTML={{
        __html: `(function(h,o,t,j,a,r){
                  h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                  h._hjSettings={hjid:1803180,hjsv:6};
                  a=o.getElementsByTagName('head')[0];
                  r=o.createElement('script');r.async=1;
                  r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                  a.appendChild(r);
                })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
                `,
      }}
    />

    <Script
      id="recaptcha"
      src={`https://www.google.com/recaptcha/api.js?render=${process.env.GATSBY_SITE_KEY}`}
    />
  </>
);

export default wrapPageElement;
