import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { graphql, useStaticQuery, Link as GatsbyLink } from 'gatsby';

import translateContent from '@core/helpers/translation';
import GatsbyImageProvider from '@core/providers/image/gatsby-image-provider';

import { MenuLink } from '@models/menu-link';

import './style.scss';

type LinkItemProps = {
  machineName: string;
  menuLink: MenuLink;
};

export default function SolutionsLinkItem({
  machineName,
  menuLink
}: LinkItemProps): ReactElement {
  const result = SolutionsQuery();
  const { t, i18n } = useTranslation();
  let items = translateContent(result.industries?.edges ?? [], i18n.language);

  if (['Solutions', 'Soluciones'].includes(machineName)) {
    items = translateContent(result.solutions?.edges ?? [], i18n.language);
  }

  return (
    <div className="mega-menu-item pt-4">
      <p className="font-weight-normal text-secondary">
        {t(`Mega Menu ${machineName} overview`)}
      </p>
      <h6 className="mega-menu-title">{t('Mega Menu The Last')}</h6>
      <div className="row">
        <div className="col-12">
          <div className="row mx-0">
            {items.slice(0, 6).map(({ node }) => (
              <div className="col-6 mb-3" key={node?.id}>
                <div className="row">
                  <div className="col-4 px-0">
                    <GatsbyImageProvider
                      nodeImage={node?.relationships?.image}
                      altImage={node?.relationships?.image?.attributes?.alt || ''}
                      className="rounded-lg-top solution-thumbnail w-100 object-fit-cover position-relative"
                      enableGatsbyImage={false}
                    />
                  </div>
                  <div className="col">
                    <GatsbyLink
                      to={node?.link?.uri || ''}
                      className="card-link text-dark-indigo"
                    >
                      {node?.title}
                    </GatsbyLink>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="col-12 d-flex justify-content-end align-bottom">
          <GatsbyLink
            className="small font-weight-bold text-cool-blue text-sm-left pl-3"
            to={menuLink?.link?.alias || ''}
          >
            {t('See more link')}
            {' >>'}
          </GatsbyLink>
        </div>
      </div>
    </div>
  );
}

function SolutionsQuery() {
  const nodes = useStaticQuery(graphql`
    query SolutionsItemsQuery {
      industries: allNodeIndustry(
        filter: { field_weight: { gte: 10 } }
        sort: { fields: field_weight, order: DESC }
      ) {
        edges {
          node {
            id
            title
            langcode
            wysiwyg: body {
              processed
              summary
            }
            link: path {
              uri: alias
              langcode
            }
            relationships {
              image: field_thumbnail {
                name

                attributes: field_media_image {
                  alt
                  title
                }
              }
            }
          }
        }
      }
      solutions: allNodeSolution(
        filter: { field_weight: { gte: 10 } }
        sort: { fields: field_weight, order: DESC }
      ) {
        edges {
          node {
            id
            title
            langcode
            wysiwyg: body {
              processed
              summary
            }
            link: path {
              uri: alias
              langcode
            }
            relationships {
              image: field_thumbnail {
                name

                attributes: field_media_image {
                  alt
                  title
                }
              }
            }
          }
        }
      }
    }
  `);

  return nodes;
}
