import React, { ReactElement } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneSquareAlt } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export default function CallUs(): ReactElement {
  const { t } = useTranslation();

  return (
    <a className="nav-link text-uppercase small p-0 d-flex align-items-center" href="tel:+ 507 2099002">
      <FontAwesomeIcon size="lg" icon={faPhoneSquareAlt as IconProp} />
      <span className="ml-2">{t('call us')}</span>
    </a>
  );
}
