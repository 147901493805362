import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { graphql, useStaticQuery, Link as GatsbyLink } from 'gatsby';

import translateContent from '@core/helpers/translation';
import GatsbyImageProvider from '@core/providers/image/gatsby-image-provider';

import { MenuLink } from '@models/menu-link';
import { Graphql } from '@models/graphql';

import './style.scss';

type LinkItemProps = {
  machineName: string;
  menuLink: MenuLink
};

export default function ClientsLinkItem({ machineName, menuLink }: LinkItemProps): ReactElement {
  const { t, i18n } = useTranslation();
  let clients = [];

  const getClients = (): Graphql[] => {
    const data = ClientsQuery();
    clients = translateContent(data?.clients?.edges ?? [], i18n.language);

    let clientsList = [];

    clientsList = clients
      .map((successStory: Graphql) => (
        { ...(successStory as unknown as object), flipped: false }
      ));

    return clientsList;
  };

  return (
    <div className="mega-menu-item pt-4">
      <p className="font-weight-normal text-secondary">
        {t(`Mega Menu ${machineName} overview`)}
      </p>
      <h6 className="mega-menu-title">{t('Mega Menu The Last')}</h6>
      <div className="row">
        <div className="col-12">
          <div className="row mx-0">
            {getClients().slice(0, 6).map(({ node }) => (
              <div className="col-4 mb-3 px-0" key={node?.id}>
                <GatsbyImageProvider
                  nodeImage={node?.relationships?.image}
                  altImage={node?.image?.alt || ''}
                  className="work-thumbnail-clients h-100 object-fit-cover position-relative"
                  enableGatsbyImage={false}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="col-12 d-flex justify-content-end align-bottom">
          <GatsbyLink
            className="small font-weight-bold text-cool-blue text-sm-left pl-3"
            to={menuLink?.link?.alias || ''}
          >
            {t('See more link')}
            {' >>'}
          </GatsbyLink>
        </div>
      </div>
    </div>
  );
}

function ClientsQuery() {
  const nodes = useStaticQuery(graphql`
  query WorkClientsQuery {
    clients: allNodeClients(limit: 12, filter: {field_weight: {gte: 0}}, sort: {fields: field_weight, order: DESC}) {
      edges {
        node {
          id
          title
          langcode
          weight: field_weight
          link: field_link {
            title
            uri
          }
          relationships {
            image: field_image {
              name: filename
              data: gatsbyImageData(layout: FIXED)
            }
          }
          image: field_image {
            alt
            title
          }
        }
      }
    }
  }
  `);

  return nodes;
}
