import React, { useEffect, useRef, useState } from 'react';
import { Navbar, Accordion, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'gatsby';
import { getPathFromInternalUrl } from '@core/helpers/link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes, faAngleRight, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { useSearch } from '@core/providers/search/search-provider';
import SearchAutocomplete from '@core/layout/navigation-bar/mega-menu/search-autocomplete';
import ImageProvider from '@core/providers/image/image-provider';
import TranslationSwitcher from '@core/layout/top-bar/translation-switcher';

import { MenuLink } from '@models/menu-link';
import { EventTarget } from '@models/event-target';
import { Graphql } from '@shared/models/graphql';

type MobileMenuProps = {
  menu: MenuLink[],
  node: Graphql,
};

export default function MobileMenu({
  menu, node
}: MobileMenuProps): React.ReactElement {
  const scrollInto = useRef<HTMLElement | null>(null);

  useEffect(() => {
    scrollInto?.current?.scrollIntoView();
  }, []);

  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const getLinks = (link: MenuLink) => {
    if (!link) {
      return <></>;
    }

    const [expanded, setExpanded] = useState(false);

    const handleExpand = () => {
      setExpanded(!expanded);
    };

    const linkTo = link.children && link.children.length > 0 ? '#' : link?.link?.alias || getPathFromInternalUrl(link?.link?.uri || '#') || '#';

    const isContact = ({ link }: MenuLink) => {
      const path = link?.alias || link?.uri || '';

      return path.includes('contact');
    };

    return (
      <div>
        <div className="text-uppercase text-marine font-weight-bolder cursor-pointer mobile-navbar-custom" onClick={handleExpand} style={{ textDecoration: 'none'}}>
          <Link to={linkTo} className={isContact(link) ? 'contact-link' : ''}
          onClick={(event) => {
            if (!(link.children && link.children.length > 0)) {
              const { target } = event as unknown as EventTarget;

              if (target.href && target.href.includes('#')) {
                toggleOpenState(true);
              } else {
                toggleOpenState(false);
              }
            }
          }}>
            <span>{link.title}</span>
          </Link>
          {link.children && link.children.length > 0 && (
            <span className='icon'><FontAwesomeIcon
              icon={expanded ? faAngleDown as IconProp : faAngleRight as IconProp}
              style={{ cursor: 'pointer', fontSize: '1.5rem', marginRight: '1rem' }}
            /></span>
          )}
        </div>

        {expanded && link.children && link.children.length > 0 && (
          <div className='text-marine font-weight-light cursor-pointer link-children'>
            {link.children.map((childLink) => (
              <Link
                key={childLink.title}
                to={childLink?.link?.alias || '#'}
                onClick={(event) => {
                  const { target } = event as unknown as EventTarget;

                  if (target.href && target.href.includes('#')) {
                    toggleOpenState(true);
                  } else {
                    toggleOpenState(false);
                  }
                }}
              >
                <div>{childLink.title}</div>
              </Link>
            ))}
          </div>
        )}
      </div>
    );
  };

  if (menu.length <= 0) {
    return <></>;
  }

  const toggleOpenState = (openState: boolean) => {
    const body: HTMLBodyElement|null = document.querySelector('body');
    const topbar: HTMLBodyElement|null = document.querySelector('.top-bar');

    if (body && topbar) {
      body.style.overflow = openState ? 'hidden' : 'auto';
      topbar.style.display = openState ? 'none' : 'block';
    }

    setIsOpen(openState);
  };

  return (
    <div className="w-100">
      <div className="d-flex align-items-center justify-content-end">
        <SearchBar />
        <Navbar.Toggle
          className="ml-4 mr-2"
          onClick={() => toggleOpenState(true)}
        />
      </div>
      {isOpen && (
        <div className="mobile-menu position-absolute top-0 border-top position-relative">
          <div className="d-flex justify-content-between align-items-center mt-2">
            <Navbar.Brand href={`/${i18n.language}`}>
              <ImageProvider
                fileName="logo-rootstack.png"
                alt="logo"
                className="navbar-small-logo ml-2 w-100 h-100"
                width="180px"
                height="50px"
                lazy={false}
              />
            </Navbar.Brand>
            <span className="close-icon cursor-pointer mr-4">
              <FontAwesomeIcon
                className=""
                icon={faTimes as IconProp}
                onClick={() => toggleOpenState(false)}
                size="lg"
              />
            </span>
          </div>
          <div className="d-flex flex-column">
            <Accordion>{menu.map(getLinks)}</Accordion>
            <div className="lang-switcher-mobile">
              <TranslationSwitcher node={node} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

function SearchBar(): React.ReactElement {
  const { showSearch, setShowSearch } = useSearch();
  const limit = 5;

  const handleToggleSearch = (isOpen: boolean) => {
    setShowSearch(isOpen);
  };

  return (
    <>
      <span className="search-icon cursor-pointer">
        <FontAwesomeIcon
          icon={faSearch as IconProp}
          onClick={() => handleToggleSearch(!showSearch)}
          size="lg"
        />
      </span>
      {showSearch && <SearchAutocomplete limit={limit} />}
    </>
  );
}
