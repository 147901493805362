import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Navbar } from 'react-bootstrap';

import { useMobile } from '@core/helpers/responsive';
import MobileMenu from '@core/layout/navigation-bar/mega-menu/mobile-menu';
import DesktopMenu from '@core/layout/navigation-bar/mega-menu/desktop-menu';
import allMainMegaMenu from '@core/layout/navigation-bar/mega-menu/default-query';
import SearchProvider from '@core/providers/search/search-provider';
import ImageProvider from '@core/providers/image/image-provider';
import { Graphql } from '@shared/models/graphql';

import { LocationModel } from '@models/location';

import './mega-menu.scss';

type MegaMenuProps = {
  navigationDisabled?: boolean
  location: LocationModel,
  node: Graphql,
};

MegaMenu.defaultProps = {
  navigationDisabled: false
};

export default function MegaMenu({
  navigationDisabled, location, node
}: MegaMenuProps): React.ReactElement {
  const { i18n } = useTranslation();
  const isMobile = useMobile();
  const mainMenu = allMainMegaMenu(isMobile);
  const navigationBar = useRef(null);

  return (
    <SearchProvider>
      <Navbar
        ref={navigationBar}
        className="navigation-bar sticky-top p-0"
        expand="lg"
      >
        <div className={`${isMobile ? 'd-flex w-100 align-items-center' : 'container'}`}>
          <a href={`/${i18n.language}`} className="navbar-brand">
            <ImageProvider
              fileName="logo-rootstack.png"
              alt="logo"
              className="navbar-small-logo ml-2"
              width={isMobile ? '180px' : '200px'}
              fluid
              lazy={false}
            />
          </a>
          {!navigationDisabled && (
            <>
              <div className="ml-auto d-block d-lg-none">
                <MobileMenu menu={mainMenu} node={node} />
              </div>
              <div className="d-none d-lg-block">
                <DesktopMenu location={location} menu={mainMenu} />
              </div>
            </>
          )}
        </div>
      </Navbar>
    </SearchProvider>
  );
}
