/* eslint-disable */
// @ts-nocheck

import React from 'react';
import { Script } from 'gatsby';

export const onRenderBody = ({ pathname, setHtmlAttributes }) => {
  const arrayPathname = pathname.split('/')
  let lang = 'en'

  if (pathname === '/' || pathname === '/404' || pathname === '/404.html' || pathname.toLowerCase().startsWith('/lp/')) {
    lang = 'en'
  } else if (arrayPathname.length >= 2) {
    lang = arrayPathname[1]
  }

  setHtmlAttributes({ lang });
};

export const wrapPageElement = ({ element }) => (
  <>
    {element}
    <Script
      id="message-bird"
      src="https://widget.flowxo.com/embed.js"
      strategy="post-hydrate"
      data-fxo-widget="eyJ0aGVtZSI6IiMxNDY2YjQiLCJ3ZWIiOnsiYm90SWQiOiI2NmViMGYwODE2YTZkMDAwNDNhNzk4MDEiLCJ0aGVtZSI6IiMxNDY2YjQiLCJsYWJlbCI6IlJvb3RzdGFjayBDaGF0In19"
    />

    <Script
      id="gtag"
      src="https://www.googletagmanager.com/gtag/js?id=UA-18995008-1"
      onLoad={() => {
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          dataLayer.push(arguments);
        }

        gtag('js', new Date());
        gtag('config', 'UA-18995008-1');
      }}
    />

    <Script
      id="ga4"
      src="https://www.googletagmanager.com/gtag/js?id=G-85ZMN92ZPP"
      onLoad={() => {
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', 'G-85ZMN92ZPP');
      }}
    />

    <Script
      id="gtag-amp"
      src="https://www.googletagmanager.com/gtag/js?id=AW-966025032"
      onLoad={() => {
        window.dataLayer = window.dataLayer || [];

        function gtag() {
          dataLayer.push(arguments);
        }

        gtag('js', new Date());
        gtag('config', 'AW-966025032');
      }}
    />

    <Script
      id="hotjars"
      dangerouslySetInnerHTML={{
        __html: `(function(h,o,t,j,a,r){
                  h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                  h._hjSettings={hjid:1803180,hjsv:6};
                  a=o.getElementsByTagName('head')[0];
                  r=o.createElement('script');r.async=1;
                  r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                  a.appendChild(r);
                })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
                `,
      }}
    />

    <Script
      id="recaptcha"
      src={`https://www.google.com/recaptcha/api.js?render=${process.env.GATSBY_SITE_KEY}`}
    />
  </>
);

export default wrapPageElement;
