import { useStaticQuery, graphql } from 'gatsby';

export default function MegaMenuCompanyQuery() {
  const nodes = useStaticQuery(graphql`
    query CompanyItemsQuery {
      partnerships: allNodePartnership(
        filter: { field_weight: { gte: 10 } }
        sort: { fields: field_weight, order: DESC }
      ) {
        edges {
          node {
            id
            title
            langcode
            link: field_link {
              uri
              title
            }
            relationships {
              image: field_client_logo {
                id
                name
                data: gatsbyImageData(layout: FIXED)
                attributes: field_media_image {
                  alt
                  title
                }
              }
              categories: field_partnership_category {
                name
                id
              }
            }
          }
        }
      }
    }
  `);

  return nodes;
}
