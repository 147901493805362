import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { graphql, useStaticQuery, Link as GatsbyLink } from 'gatsby';

import translateContent from '@core/helpers/translation';
import truncate from '@core/helpers/truncate';
import DomPurify from '@core/helpers/dom-purify';
import GatsbyImageProvider from '@core/providers/image/gatsby-image-provider';

import { Graphql } from '@shared/models/graphql';

import { MenuLink } from '@models/menu-link';

import './style.scss';

type LinkItemProps = {
  menuLink: MenuLink;
};

export default function BlogLinkItem({
  menuLink
}: LinkItemProps): ReactElement {
  const { t, i18n } = useTranslation();
  const result = BlogQuery();
  const items = translateContent(result.blogs?.edges ?? [], i18n.language);

  return (
    <div className="mega-menu-item pt-4">
      <p className="font-weight-normal text-secondary">
        {t('Mega Menu Blog overview')}
      </p>
      <h6 className="mega-menu-title">{t('Mega Menu The Last')}</h6>
      <div className="row">
        <div className="col-12">
          <div className="row mx-0">
            {items.slice(0, 6).map(({ node }: Graphql) => (
              <div className="col-6 mb-3" key={node?.id}>
                <div className="row">
                  <div className="col-4 px-0">
                    <GatsbyImageProvider
                      nodeImage={node?.relationships?.image}
                      altImage={node?.relationships?.image?.attributes?.alt || ''}
                      className="rounded-lg-top resources-thumbnail w-100 object-fit-cover position-relative"
                      enableGatsbyImage={false}
                    />
                  </div>
                  <div className="col-8">
                    <GatsbyLink
                      to={node?.path?.alias || ''}
                      className="card-link text-dark-indigo"
                    >
                      <DomPurify text={truncate(node?.title as string, 50)} />
                    </GatsbyLink>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="col-12 d-flex justify-content-end align-bottom">
          <GatsbyLink
            className="small font-weight-bold text-cool-blue text-sm-left pl-3"
            to={menuLink?.link?.alias || ''}
          >
            {t('See more link')}
            {' >>'}
          </GatsbyLink>
        </div>
      </div>
    </div>
  );
}

function BlogQuery() {
  const nodes = useStaticQuery(graphql`
    query BlogQuery {
      blogs: allNodeBlog(limit: 6, sort: { fields: created, order: DESC }) {
        edges {
          node {
            id
            title
            langcode
            path {
              alias
              langcode
            }
            relationships {
              image: field_thumbnail {
                name

                attributes: field_media_image {
                  alt
                  title
                }
              }
            }
          }
        }
      }
    }
  `);

  return nodes;
}
