import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import TranslationProvider from '@core/providers/i18n/translation-provider';
import CountryProvider from '@core/providers/country/country-provider';
import Seo from '@core/seo/seo';
import { isBrowser } from '@core/helpers/ssr';
import Footer from '@core/layout/footer/footer';
import Header from '@core/layout/header/header';
import Cookie from '@core/layout/cookie/cookie';
import BannerBottom from '@core/layout/banner-bottom/banner-bottom';

import { LocationModel } from '@models/location';
import { PageContext } from '@models/page-context';

import '@styles/base.scss';

const { none, noLayout } = require('@config/styles');

type LayoutProps = {
  children: React.ReactElement;
  pageContext: PageContext;
  location: LocationModel;
};

function Layout({
  children,
  location,
  pageContext
}: LayoutProps): React.ReactElement {
  const { i18n, t } = useTranslation();
  const { language, node, pagination } = pageContext;

  const headerStyle = node?.headerStyle;
  const isHeaderEnabled = headerStyle !== none;
  const hideLayout = pageContext.hideLayout || headerStyle === noLayout;
  const ChildrenComponent = React.cloneElement(
    children,
    { t, language, pagination },
    null
  );

  useEffect(() => {
    const asyncChangeLanguage = async () => {
      i18n.changeLanguage(language);
    };

    asyncChangeLanguage();
  }, []);

  useEffect(() => {
    if (isBrowser) {
      const storedCampaignData = localStorage.getItem('campaign_data');
      let campaignData;

      try {
        campaignData = storedCampaignData
          ? JSON.parse(storedCampaignData)
          : null;
      } catch (error) {
        campaignData = null;
      }

      if (
        !campaignData ||
        !campaignData.utm_source ||
        campaignData.utm_campaign
      ) {
        const urlSearchParams: URLSearchParams = new URLSearchParams(
          window.location.search
        );
        const params = Object.fromEntries(urlSearchParams.entries()) || {};

        campaignData = {
          utm_source: params.utm_source || null,
          utm_medium: params.utm_medium || null,
          utm_campaign: params.utm_campaign || null,
          gclid: params.gclid || null
        };

        if (campaignData.utm_source && campaignData.utm_medium) {
          localStorage.setItem('campaign_data', JSON.stringify(campaignData));
        } else {
          localStorage.removeItem('campaign_data');
        }
      }
    }
  }, []);

  return (
    <CountryProvider>
      {!hideLayout ? <BannerBottom node={node} language={language} /> : <></>}
      <Seo node={node} pagination={pagination} type="search" />
      {hideLayout ? (
        ChildrenComponent
      ) : (
        <>
          {isHeaderEnabled && <Header node={node} location={location} />}
          {ChildrenComponent}
          <Footer />
        </>
      )}
      <Cookie />
    </CountryProvider>
  );
}

export default TranslationProvider(Layout);
