import React from 'react';
import { GatsbyImageProps } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';

import { Image } from '@shared/models/image';
import { Graphql } from '@shared/models/graphql';

type GatsbyImageProviderProps = Pick<
  GatsbyImageProps,
  'className' | 'objectFit' | 'style'
> & {
  nodeImage: Image | undefined;
  enableGatsbyImage?: boolean;
  altImage?: string;
};

let allFiles = [] as Graphql[];

export default function GatsbyImageProvider(
  props: GatsbyImageProviderProps
): React.ReactElement {
  const { nodeImage, altImage, enableGatsbyImage } = props;

  if (!nodeImage?.name) {
    return <div />;
  }

  if (allFiles.length <= 0) {
    const { allFile } = useStaticQuery(graphql`
      query {
        allFile(
          filter: {
            extension: { regex: "/(jpg)|(png)|(jpeg)|(svg)/" }
          }
        ) {
          edges {
            node {
              base
              extension
              publicURL
              id
            }
          }
        }
      }
    `);

    if (allFile && allFile.edges) {
      allFiles = allFile.edges;
    }
  }

  const file = allFiles.find((edge) => {
    const { base } = edge?.node || {};
    return base === encodeURIComponent(nodeImage?.name) || decodeURIComponent(`${base}`) === nodeImage?.name;
  });

  if (!file || !file.node) {
    return <div />;
  }

  const { node } = file;

  return (
    <img
      className={props.className}
      src={`${node.publicURL}`}
      alt={altImage || (nodeImage?.attributes?.alt as string) || ''}
      title={altImage}
    />
  );
}

GatsbyImageProvider.defaultProps = {
  enableGatsbyImage: true,
  altImage: '',
};
